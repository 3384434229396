@font-face {
    font-family: 'Papirus';
    src: url('./1papyrus.ttf') format('truetype');
}

#navbar {
    transition: 0.4s;
}

#logo {
    transition: 0.4s;
}

.bWhite {
    background-image: linear-gradient(to right, #f4f9e8, #ddebf5);
    border-bottom: solid #b1d0e5;
    opacity: 0.9;
}

.bGray {
    background-image: linear-gradient(to right, #edf7d7, #cadeeb);
}

.bckg1 {
    background-image: linear-gradient(to right, #ffffff, #eef5fa);
}

.bckg2 {
    background-image: linear-gradient(to right, #f6f9ee, #ffffff);
}

.btnLng {
    margin-left: 10px;
    margin-right: 10px;
}

.shadow1 {
    box-shadow: 0.5rem 0.25rem 0.5rem rgba(0, 0, 0, .65);
    padding: 0;
}

.shadowtext {
    box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, .95) !important;
    padding: 20px;
}

.flex {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.menuButton {
    border: solid 1px;
    padding: 0px;
}

.mlink {
    align-items: center;
    text-transform: uppercase;
    justify-content: center;

}

a.nav-link {
    color: #524E4E;
    font-size: calc(0.4vw + 12px);
}

.dropdown-item {
    font-size: calc(0.4vw + 12px);
    padding-right: 5px;
    padding-left: 5px;
}

.dropdown-divider {
    margin: 2px
}

.menuButton .nav-link {
    padding: 0px;
    margin-right: 0;
}

.mbg {
    font-family: 'Times New Roman', Times, serif;
    /* color: #131216; */
    color:#524E4E;
}

.mbg .navbar-toggler.collapsed {
    margin-right: 10px;
}

.papirus {
    margin-top: calc(-25vw - 20px);
    font-family: 'Papirus', sans-serif;
    font-size: calc(2.2vw + 10px);
    opacity: 0.95;
    padding-right: calc(2vw);
    padding-left: calc(2vw);
    text-align: end;
    color: black;
}

.opc {
    opacity: 0.85;
}

.mtop {
    justify-content: center;
    /* color: #131216; */
    /* padding-top: calc(3vw); */
    padding-left: calc(6vw);
    padding-right: calc(6vw);
}

.mh3 {
    line-height: 1.3;
    font-size: calc(0.5vw + 14px);
}

.mh3p {
    line-height: 1.3;
    font-size: calc(0.5vw + 14px);
    padding-top: calc(1vw + 12px);
    text-align: justify;
    display: inline-block;

}

.mh3n {
    line-height: 1em;
    font-size: calc(0.5vw + 12px);
    text-align: justify;
}

.mh3p1 {
    line-height: 1.3;
    font-size: calc(0.5vw + 14px);
    font-weight: bold;
    text-align: center;
    /* color: black;
    text-decoration: none; */
}

.mh3p1 a  {
    line-height: 1.3;
    font-size: calc(0.5vw + 14px);
    font-weight: bold;
    text-align: center;
    color: black;
    /* text-decoration: none; */
}

.mh1 {
    line-height: 1.3;
    font-size: calc(0.5vw + 18px);
    font-style: italic;
}

.h1M {
    color: green;
    font-size: calc(0.5vw + 16px);
    margin-left: calc(0.6vw + 5px);
    font-style: italic;
    display: inline-block;
}

.h1M1 {
    font-size: calc(1.2vw + 14px);
    margin-left: calc(0.6vw + 5px);
    font-style: bold;
    font-weight: 600;
}

.h3M {
    color: green;
    font-size: calc(0.5vw + 14px);
    padding-top: calc(1vw + 5px);
    font-style: italic;
    line-height: 1.3;
}

.nowrap {
    display: inline-block;
}

.va-center {
    align-items: center;
}

.mblink {
    line-height: 1.3;
    font-size: calc(0.5vw + 14px);
    text-decoration: underline;
}

.mblink a {
    /* color: #131216; */
    font-size: calc(0.4vw + 16px);
}

.dropdown-menu {
    --bs-dropdown-min-width: 5rem;
}

.par1 {
    z-index: -2;
    max-width: calc(38vw);
    position: fixed;
    top: 100px;
    margin-left: 0%;
    /* max-width: 30%; */
}

.par2 {
    z-index: -2;
    max-width: calc(38vw);
    position: fixed;
    top: 130px;
    margin-left: 0%;
    /* max-width: 30%; */
}

.mbg1 {
    background-color: #ffffff;
    justify-content: center;
}

.bck {
    background-image: url('./images/logobig.webp');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    /* margin-top: 100px; */
}

.ptm {
    /* padding-top: 250px; */
    padding-top: calc(80px + 35vw );
}

.hr1 {
    opacity: 1;
    margin: 0;
    background-color: white;
}

.stick1 {
    position: sticky;
    top: 60px;
    /* margin-top:60px; */
    border-bottom: solid 1px;
}

.mh1 {
    height: 120px;
}

.mh2 {
    height: 15vw;
}

.btn-lg {
    font-size: calc(0.4vw + 12px);
}